.layoutContainer {
  min-height: 100vh;
}
.container {
  margin: 24px 16px;
}

.loadingContainer {
  height: 100vh;
  min-height: 100vh;
}

.loadingSpin {
  width: 100%;
  min-height: 100vh;
}

.mcLogo {
  margin: 20px;
  /* height: 17px; */
  /* background-image: url('/images/logo/medicloud-logo-text-white.svg'); */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.header {
  padding: 0;
  color: #fff !important;
}

.userName {
  margin-left: 10px;
}

.menuDivider {
  border-bottom: 1px solid #36373b;
  margin: 0;
}

.siderFooter {
  font-size: 12px;
  position: absolute;
  overflow: hidden;
  bottom: 0;
  width: 100%;
  padding: 10px 0 10px 25px;
  color: #505056;
}

@media (prefers-color-scheme: light) {
  .mcLogo {
    filter: invert(1);
  }

  .logo {
    filter: invert(1) drop-shadow(0 0 0.3rem #ffffff70);
  }
}
