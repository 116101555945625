.layoutContainer {
  position: absolute;
  z-index: 10000;
  min-height: 100vh;
}

.loadingContainer {
  height: 100vh;
  min-height: 100vh;
}

.loadingSpin {
  width: 100%;
  min-height: 100vh;
}
